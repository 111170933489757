
































































































































































import Vue from 'vue';
const Ripple = require('vue-ripple-directive');
import axios from 'axios';
import { MonitorType, ThresholdConfig } from './types';
import { isNumber } from 'highcharts';

interface EditMonitorType extends MonitorType {
  dirty?: boolean;
  levelSeq?: string;
}

export default Vue.extend({
  components: {},
  directives: {
    Ripple,
  },
  data() {
    const columns = [
      {
        key: '_id',
        label: '代碼',
        formatter: (v: string) => {
          if (v === 'WD_SPEED' || v === 'WD_DIR') return `${v} (向量計算)`;
          else return v;
        },
      },
      {
        key: 'desp',
        label: '名稱',
      },
      {
        key: 'unit',
        label: '單位',
      },
      {
        key: 'prec',
        label: '小數點位數',
      },
      {
        key: 'order',
        label: '順序',
      },
      {
        key: 'std_law',
        label: '法規值',
      },
      {
        key: 'zd_law',
        label: '零點偏移法規',
      },
      {
        key: 'span',
        label: '全幅值',
      },
      {
        key: 'span_dev_law',
        label: '全幅值偏移法規',
      },
      {
        key: 'levelSeq',
        label: '分級(以逗點分隔)',
      },
      {
        key: 'calbrate',
        label: '校正回歸',
        tdClass: { 'text-center': true },
      },
      {
        key: 'fixedM',
        label: 'M',
        tdClass: { 'text-center': true },
      },
      {
        key: 'fixedB',
        label: 'B',
        tdClass: { 'text-center': true },
      },
      {
        key: 'accumulated',
        label: '累積',
        tdClass: { 'text-center': true },
      },
      {
        key: 'measuringBy',
        label: '測量儀器',
        formatter: (
          value: null | Array<string>,
          key: string,
          item: Array<string>,
        ) => {
          if (value !== null) return `${value.join(', ')}`;
          else return '';
        },
      },
    ];
    const monitorTypes = Array<EditMonitorType>();

    let thresholdConfig: ThresholdConfig = {
      elapseTime: 30,
    };

    const form = {
      thresholdConfig,
    };
    return {
      display: false,
      columns,
      monitorTypes,
      editingMt: {
        thresholdConfig: {},
      },
      form,
      selected: Array<MonitorType>(),
    };
  },
  mounted() {
    this.getMonitorTypes();
  },
  methods: {
    getMonitorTypes() {
      axios.get('/MonitorType').then(res => {
        this.monitorTypes = res.data;
        for (const mt of this.monitorTypes) {
          if (mt.levels !== undefined) {
            mt.levelSeq = mt.levels.join(',');
          }
        }
      });
    },
    justify(mt: any) {
      if (mt.span === '') mt.span = null;
      if (mt.span_dev_internal === '') mt.span_dev_internal = null;
      if (mt.span_dev_law === '') mt.span_dev_law = null;
      if (mt.zd_internal === '') mt.zd_internal = null;
      if (mt.zd_law === '') mt.zd_law = null;
      if (mt.std_internal === '') mt.std_internal = null;
      if (mt.std_law === '') mt.std_law = null;
      if (mt.levelSeq) {
        try {
          let levelSeq = mt.levelSeq as string;
          let levels = levelSeq.split(',').map(t => parseFloat(t));
          mt.levels = levels;
        } catch (err) {}
      }

      if (!isNumber(mt.fixedB)) mt.fixedB = undefined;
      if (!isNumber(mt.fixedM)) mt.fixedM = undefined;
    },
    checkLevel(levelSeq: string | undefined): boolean {
      try {
        if (levelSeq === undefined) return true;

        let levels = levelSeq.split(',').map(t => parseFloat(t));

        if (levels.length >= 1 && levels.every(l => !isNaN(l))) return true;
        else {
          this.$bvModal.msgBoxOk(`${levelSeq}不是有效的分級!`);
          return false;
        }
      } catch (err) {
        this.$bvModal.msgBoxOk(`${levelSeq}不是有效的分級!`);
        return false;
      }
    },
    save() {
      const all = [];
      for (const mt of this.monitorTypes) {
        if (mt.dirty) {
          this.justify(mt);
          all.push(axios.put(`/MonitorType/${mt._id}`, mt));
        }
      }

      Promise.all(all).then(() => {
        this.getMonitorTypes();
        this.$bvModal.msgBoxOk('成功');
      });
    },
    markDirty(item: any) {
      item.dirty = true;
    },
    setMtThresholdConfig() {
      this.editingMt.thresholdConfig = this.form.thresholdConfig;
      this.markDirty(this.editingMt);
    },
    onMtSelected(items: Array<MonitorType>) {
      this.selected = items;
    },
    async removeMt() {
      let deletedMts = this.selected.map(p => p._id);
      let ret = await this.$bvModal.msgBoxConfirm(
        `請確認要刪除${deletedMts.join(',')}等測項`,
      );
      if (ret === true) {
        try {
          let allP = deletedMts.map(_id => {
            return axios.delete(`/MonitorType/${_id}`);
          });
          await Promise.all(allP);
          this.getMonitorTypes();
        } catch (err) {
          throw new Error('Failed to delete mt');
        }
      }
    },
  },
});
